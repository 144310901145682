// src/components/ProductTable.js
import React from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';

const ProductTable = ({ products, onShowDetails, onSort, sortField, sortOrder }) => {
    const renderSortIcon = (field) => {
        if (sortField !== field) return <FaSort />;
        return sortOrder === 'asc' ? <FaSortUp /> : <FaSortDown />;
    };

    const handleSort = (field) => {
        const newOrder = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        onSort(field, newOrder);
    };

    return (
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th onClick={() => handleSort('item_id')} style={{ cursor: 'pointer' }}>
                        ID {renderSortIcon('item_id')}
                    </th>
                    <th onClick={() => handleSort('item_title')} style={{ cursor: 'pointer' }}>
                        Title {renderSortIcon('item_title')}
                    </th>
                    <th onClick={() => handleSort('unit_price')} style={{ cursor: 'pointer' }}>
                        Price {renderSortIcon('unit_price')}
                    </th>
                    <th onClick={() => handleSort('isbn')} style={{ cursor: 'pointer' }}>
                        ISBN {renderSortIcon('isbn')}
                    </th>
                    <th onClick={() => handleSort('ean13')} style={{ cursor: 'pointer' }}>
                        EAN13 {renderSortIcon('ean13')}
                    </th>
                    <th onClick={() => handleSort('items.mtanufacture')} style={{ cursor: 'pointer' }}>
                        Publisher {renderSortIcon('items.mtanufacture')}
                    </th>
                    <th onClick={() => handleSort('authors')} style={{ cursor: 'pointer' }}>
                        Authors {renderSortIcon('authors')}
                    </th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {products.map((product) => (
                    <tr key={product.item_id}>
                        <td>{product.item_id}</td>
                        <td>{product.item_title}</td>
                        <td>{product.unit_price.toLocaleString('en-US', {style: 'currency', currency: 'LKR'})}</td>
                        <td>{product.isbn}</td>
                        <td>{product.ean13}</td>
                        <td>{product.mtanufacture}</td>
                        <td>{product.authors}</td>
                        <td>
                            <Button variant="primary" onClick={() => onShowDetails(product)}>View</Button>
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default ProductTable;
