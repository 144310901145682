// src/components/AdvancedSearch.js
import React from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const AdvancedSearch = ({ onSearch }) => {
    const handleAdvancedSearch = (e) => {
        e.preventDefault();
        const { title, author, publisher, category } = e.target.elements;
        onSearch("title="+title.value + 
            "&author="+author.value+
            "&publisher="+publisher.value +
            "&isbn="+category.value);
    };

    return (
        <Form onSubmit={handleAdvancedSearch} className="mb-4">
            <Row>
                <Col md={3}>
                    <Form.Control type="text" name="title" placeholder="Book Title" />
                </Col>
                <Col md={3}>
                    <Form.Control type="text" name="author" placeholder="Author" />
                </Col>
                <Col md={3}>
                    <Form.Control type="text" name="publisher" placeholder="Publisher" />
                </Col>
                <Col md={3}>
                    <Form.Control type="text" name="category" placeholder="ISBN" />
                </Col>
            </Row>
            <Button type="submit" className="mt-3">Search</Button>
        </Form>
    );
};

export default AdvancedSearch;
